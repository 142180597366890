.uowcolor {
  /* background-color: #4b2e83; */
  background: linear-gradient(95deg,#32006e,#32006e 45%,#76236c);
}
.buttoncustomsearch {
  background-color: white;
  border: none;
  color: black;

  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin-top: 10px;
}
.form-control-dark {
  color: #fff;
  background-color: #4b2e83;
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar {
  padding: 0.75rem 1rem;
  border-width: 0;
  height: 60px;
  border-radius: 0;
}
.fnavbar {
  padding: 0.75rem 1rem;
  border-width: 0;
  height: 30px;
  border-radius: 0;
}
.fcolor {
  /* background-color: #4b2e83; */
  background-color: #e3e4e5;
  /*background-color: white; */
}
.form-control {
  padding: 0.75rem 1rem;
  border-width: 1;
  /* margin-bottom: 10; */
  border-color: #4b2e83;
  border-radius: 2;
}
.sidebar .nav-link {
  font-weight: 520;
  color: #333;
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #999;
}

.sidebar .nav-link {
  color: #4b2e83;
}
.active {
  /* border-top: 1px solid #4b2e83;
  border-bottom: 1px solid #4b2e83; */
  border-right: 10px solid #4b2e83;
}
.active1 {
  /* border-top: 1px solid #4b2e83;
  border-bottom: 1px solid #4b2e83; */
  border-right: 5px solid #4b2e83;
}
.sidebar :link:hover {
  color: #4b2e83;
}
.sidebar :link:active {
  background-color: white;
}
.collapseicon:hover {
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
  border-left: 2px solid #4b2e83;
  border-top: 2px solid #4b2e83;
  border-bottom: 2px solid #4b2e83;
  /* background-color: #4b2e83; */
  /* color: white; */
}

/* .sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
} */

.sidebar-heading {
  font-size: 0.75rem;
  text-transform: uppercase;
}
.feather {
  width: 18px;
  height: 18px;
  vertical-align: text-bottom;
}

/*
   * Sidebar
   */
#sidebar {
  /* z-index: 1; */
  position: fixed;

  /* overflow-x: hidden; */
  width: 230px;
  height: 100%;
  overflow-y: auto !important;
  z-index: 100;
  background: white;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
  /* opacity: 0.9; */
  scrollbar-width: thin;
}

#sidebar::-webkit-scrollbar {
  width: 1px;
}

#sidebar::-moz-scrollbar {
  width: 1px;
}

#sidebar2 {
  /* z-index: 1; */
  position: fixed;
  width: 74px;

  height: 100%;
  overflow-y: hidden;
  z-index: 100;
  background: white;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
  /* opacity: 0.9; */
}
.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;

  z-index: 100; /* Behind the navbar */
  padding: 72px 0 0; /* Height of navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
}
.sidebar-nav {
  padding: 0;
  list-style: none;
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 72px);
  padding-top: 0.5rem;
  /* overflow-x: hidden; */
  overflow-y: hidden; /* Scrollable contents if viewport is shorter than content. */
}
.TrebuchetFont {
  font-family: "Trebuchet MS";
}
[role="main"] {
  padding-top: 133px; /* Space for fixed navbar */
}
[role="main2"] {
  padding-top: 113px; /* Space for fixed navbar */
}
[role="main3"] {
  padding-top: 113px; /* Space for fixed navbar */
}
@media (min-width: 768px) {
  [role="main"] {
    padding-top: 72px; /* Space for fixed navbar */
  }
}
@media (min-width: 768px) {
  [role="main2"] {
    padding-top: 50px; /* Space for fixed navbar */
  }
}
@media (min-width: 768px) {
  [role="main3"] {
    padding-top: 30px; /* Space for fixed navbar */
  }
}

#main > div {
  float: top;
  margin: 10px;
}
#d1 {
  width: "100%";
  height: "100%";
}

#d2 {
  width: "100%";
  height: "100%";
}
#main {
  position: absolute;
  width: "100%";
  height: "100%";
}
.logoName {
  display: block;
}
/* .logoDiv {
  width: 63%;
} */
@media screen and (max-width: 1200px) {
  /* .logoName {
    display: none;
  } */
  /* .logoDiv {
    width: auto;
  } */
}
.user-name {
  display: block;
}
.sync-data {
  width: 100%;
}
@media screen and (max-width: 770px) {
  .user-name {
    display: none;
  }
  .sync-data {
    /* width: 50%; */
  }
}
.quilly {
  /*display: flex;*/
  word-break: break-all
}
.quilly p {
  margin: 0;
}
.quilly a {
  background-color: inherit !important;
}
