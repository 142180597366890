.buttoncustom {
  background-color: #4b2e83;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  width: 120px;
}

.buttoncustom2 {
  background-color: red;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;

  text-decoration: none;
  display: inline-block;
  width: 120px;
}
.button2 {
  background-color: white !important;
  color: #f44336 !important;
  border: 2px solid #f44336 !important;
  padding: 9px 25px !important;
  /* padding: 14px 31px;
  border: 1px solid red;  */
}
.button1 {
  background-color: white !important;
  color: #4b2e83 !important;
  border: 2px solid #4b2e83 !important;
  padding: 9px 25px !important;
  height: 45px;
  /* padding: 14px 31px;
  border: 1px solid red;  */
}
.buttonr {
  background-color: white !important;
  color: #4b2e83 !important;
  /* border: 2px solid #4b2e83 !important; */
  padding: 9px 25px !important;
  height: 56px;
  /* padding: 14px 31px;
  border: 1px solid red;  */
}
.button2:hover {
  background-color: #f44336 !important;
  color: white !important;
  /* padding: 14px 31px;
  border: 1px solid red;  */
}
.button1:hover {
  background-color: #4b2e83 !important;
  color: white !important;
  text-decoration: none;
  /*padding: 14px 31px;
  border: 1px solid #4b2e83; */
}
.button2:disabled {
  background-color: #e9e9e9 !important;
  color: #7e7e7e !important;
  border: 2px solid #e9e9e9 !important;
  /*padding: 14px 31px;
  border: 1px solid #4b2e83; */
}
.button1:disabled {
  background-color: #4b2e83;
  color: #7e7e7e !important;
  border: 2px solid #e9e9e9 !important;
  /*padding: 14px 31px;
  border: 1px solid #4b2e83; */
}
.button3:hover {
  background-color: white !important;
  color: #4b2e83 !important;
  /* padding: 14px 31px;
  border: 1px solid red;  */
}
