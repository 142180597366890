.centerelements {
  position: relative;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
  text-align: center;
}
.centerelements2 {
  position: relative;
  top: 50%;
  bottom: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.centerelements3 {
  position: relative;
  top: 10%;
  left: 50%;

  transform: translate(-50%, -50%);
  text-align: center;
}
