.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.uowDashboard-paper {
  /* background-color: #f6f6f6; */
  padding: 18px;
  margin-bottom: 0px;
}
.uowDashboard-block-title {
  font-size: 25px;
  font-weight: bold;
  color: #333;
  opacity: 0.9;
  display: block;
  padding: 0px 8px 8px 5px;
}
.uowDashboard-nav-title {
  font-size: 25px;
  font-weight: bold;
  color: #333;
  opacity: 0.9;
  display: block;
  padding: 0px 8px 8px 5px;
}
.uowDashboard-nav-title:hover {
  color: #4b2e83;
}
.uowDashboard-wrapper {
  /* background-color: aliceblue; */
}
.uowDashboard-item {
  display: inline-block;
  background-color: #282c34;
  color: #fff;
  width: 253px;
  border: 2px solid white;
  height: 250px;
  /*padding: 18px 4px 11px 7px;*/
  position: relative;
}
.uowDashboard-item-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2px 0px 2px 0px;
  overflow: visible;
}
.uowDashboard-thevalue {
  display: inline-block;
  font-size: 40px;
  font-weight: bold;
  padding: 0px;
  margin: 10px;
  margin-top: -3px;
  text-decoration: underline;
}
.uowDashboard-circular {
  width: 100px;
  height: 115px;
  font-size: 25px;
  font-weight: bold;
  margin-right: 0px;
}

.uowDashboard-header {
  margin-left: 0px;
  font-weight: bold;
  font-size: 18px;
}
.uowDashboard-description {
  font-weight: normal;
  font-size: 11px;
  font-style: italic;
}

.uowDashboard-itemicon {
  position: absolute;
  right: 0;
  bottom: 0;
  margin-right: 5px;
  margin-bottom: 5px;
  width: 36px;
  height: 36px;
}
.itemicon-battery {
  background: url("images/battery-con.png") no-repeat;
}
.itemicon-noconnection {
  background: url("images/battery-con.png") no-repeat;
}

.uowDashboard-item.item-1 {
/*  background-color: #49d0f5;*/
  background-color: #49d0f5;
}
.uowDashboard-item.item-2 {
  background-color: #f9b429;
}
.uowDashboard-item.item-3 {
  background-color: #3885d7;
}

/* .App-link {
  color: #61dafb;
} */

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

coll-table .th,
coll-table .td {
  padding: 5px !important;
  vertical-align: baseline !important;
  border: 1px solid black !important;
}

.image:hover {
  transform: scale(3);
}
