.centered {
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
  text-align: center;
}
.centeredvertical {
  position: absolute;
  top: 50%;
  bottom: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.logocenteredvertical {
  position: absolute;
  top: 50%;
  bottom: 50%;
  left: 20%;
  right: 20%;
  margin: auto;
  height: 50px;
}
.uowcolor {
  background-color: #4b2e83;
}

.splitl {
  height: 100%;
  width: 50%;
  position: fixed;
  z-index: 1;
  top: 0;
  overflow-x: hidden;
  padding-top: 20px;
}
.splitr {
  height: 100%;
  width: 50%;
  position: fixed;
  z-index: 1;
  top: 0;
  overflow-x: hidden;
  padding-top: 20px;
}

@media screen and (max-width: 900px) {
  .splitl {
    /* height: 80%; */
    width: 100%;
    /* margin-top: 15%; */
  }
  .splitr {
    /* height: 80%; */
    width: 100%;
    /* margin-top: 15%; */
  }
  .centeredvertical {
    display: none;
  }
}

/* Control the left side */
.left {
  left: 0;
}

/* Control the right side */
.right {
  right: 0;
}
.vl {
  border-left: 3px solid #ffffff;
  box-shadow: 0 0 4px 0 #ffffff;
  height: 300px;
}
.buttoncustom {
  background-color: #4b2e83;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin-top: 10px;
}
.button:hover {
  background-color: white;
  color:#4b2e83;
  padding: 14px 31px;
  border: 1px solid #4b2e83;
}

.login-page {
  width: 330px;
  padding: 8% 0 0;
  margin: auto;
}

.formcustom {
  position: absolute;
  /* z-index: 1; */
  background: #ffffff;
  width: 350px;

  padding: 40px 40px 20px 40px;
  text-align: center;
  box-shadow: 0 0 3px 0 #ffff, 0 2px 2px 0 #fff;
}

@media screen and (max-width: 500px) {
  .formcustom {
    width: 300px;
  }
}

.or {
  display: table;
  font-size: .875rem;
  margin: 1rem 0 2rem;
  text-align: center;
  width: 100%;
}

.or:after, .or:before {
  display: table-cell;
  content: "";
  border-bottom: 1px solid #eee;
  height: 1px;
  width: 50%;
}

.or:after, .or:before {
  display: table-cell;
  content: "";
  border-bottom: 1px solid #eee;
  height: 1px;
  width: 50%;
}

.or span {
  height: 2rem;
  width: 2rem;
  display: inline-block;
  border: 1px solid #eee;
  border-radius: 50%;
  margin-bottom: -1rem;
  line-height: 1.8rem;
  vertical-align: middle;
}

