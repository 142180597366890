.upload-container {
    flex: 1 1 0%;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    padding: 15px;
    border-width: 2px;
    border-radius: 5px;
    border-color: rgb(238, 238, 238);
    border-style: solid;
    background-color: rgb(250, 250, 250);
    color: rgb(189, 189, 189);
    outline: none;
    transition: border 0.24s ease-in-out 0s;
}
.drop-zone {
    width: 100%;
    text-align: center;
    border-width: 2px;
    border-radius: 2px;
    border-color: rgb(238, 238, 238);
    border-style: dashed;
}
td.upload {
    border: 1px solid #dddddd;
    /*padding: 8px;*/
}
table {
    text-align: left;
}
.summary {
    display: flex;
    flex-direction: column;
    gap: 12px
}
.summary div {
    display: flex;
    gap: 12px
}