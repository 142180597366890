#sidebar {
  /* z-index: 1; */
  position: fixed;
  width: 230px;
  height: 100%;
  overflow-y: hidden;
  z-index: 100;
  background: white;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
  /* opacity: 0.9; */
}

#content {
  width: 100%;
  /* position: absolute; */

  padding-left: 230px;
  /*padding-bottom: 40px; */
}

#wrapper.menudisplayed #sidebar {
  width: 250px;
}
.sidebar .nav-link.active {
  color: #4b2e83;
}
.sidebar .nav-link {
  font-weight: 520;
  color: #333;
}
#wrapper.menudisplayed #content {
  padding-left: 250px;
}

.sidebar-nav {
  padding: 0;
  list-style: none;
}

.sidebar-nav li {
  text-indent: 5px;
  line-height: 40px;
}

.sidebar-nav li a {
  display: block;
  text-decoration: none;
  color: #333;
}

.sidebar-nav li a:hover {
  color: #4b2e83;
}
/* .sidebar-nav li a:active {
  color: #4b2e83;
} */
.navbar {
  padding: 0.75rem 1rem;
  border-width: 0;
  height: 60px;
  border-radius: 0;
}
.nav-linkactive {
  color: #4b2e83;
  display: block;
  text-decoration: none;
  text-indent: 5px;
  line-height: 40px;
  /* background: #4b2e83; */
}
.nav-link2 {
  color: black;
  display: block;
  text-decoration: none;
  text-indent: 5px;
  line-height: 40px;
  /* background: #4b2e83; */
}
